<template>
    <div class="units">
        <div>
            <b-row>
                <b-col
                    lg="3"
                    md="6"
                    v-for="unitItem in paginationList"
                    :key="unitItem.id"
                >
                    <ColorCard
                        :type="'unit'"
                        :dto="unitItem"
                        :subjectName="unitItem.subjectName"
                        :className="unitItem.className"
                        :semester="unitItem.semester"
                        :colors="unitItem.colors"
                        :name="unitItem.name"
                        :studentsCount="unitItem.studentsCount"
                        @details="unitDetail(unitItem)"
                        @delete="delUnit(unitItem.id)"
                        @edit="editUnit(unitItem)"
                    >
                    </ColorCard>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    <ek-pagination
                        :items="unitsListResult"
                        v-model="paginationList"
                        :pageLength="8"
                    />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ColorCard from "@/app/admin/components/ElearningCard/ColoredCard.vue";
export default {
    data() {
        return {
            paginationList: []
        };
    },
    components: {
        ColorCard
    },
    computed: {
        ...mapGetters(["unitsListResult"]),
        ...mapState({ semesterList: ({ units }) => units.semesterList })
    },
    methods: {
        getSemesterName(semesterId) {
            const semester = this.semesterList.find(el => el.id == semesterId);
            return semester ? semester.name : '';
        },
        unitDetail(item) {
            this.$router.push({
                path: `/admin/units/${item.id}`,
                query: { name: item.name }
            });
        },
        delUnit(id) {
            this.deleteUnit(id);
        },
        editUnit(dto) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_UNIT_DTO", dto);
        },
        ...mapActions(["getAllUnits", "deleteUnit", 'getSubjectOptions',"getSettingSemester "])
    },
    created() {
        this.getAllUnits();
        this.getSubjectOptions()
        this.getSettingSemester();

    }
};
</script>

<style lang="scss">
@import "/src/@core/scss/core.scss";
.units {
    ul {
        li {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
    .unicon.button {
        svg {
            fill: $main-color !important;
        }
    }
    h5 {
        margin: 0;
        margin-left: 10px;
    }
    svg {
        fill: #6e6b7b;
    }
    .card-footer {
        text-align: center;
        cursor: pointer;
        button {
            color: #fff !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}
</style>
